import React from "react"

import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const ElderLawTraverseCity = () => (
  <Layout>
    <SEO title="Elder Law in Traverse City &amp; Northern Michigan" />
    <section className="section">
      <div className="container">
        <div className="columns">
          <div className="column is-full is-8-widescreen">
            <h1 className="title is-size-1">
              Elder Law in Traverse City &amp; Northern Michigan
            </h1>
            <h2 className="subtitle is-size-3">
              At Rysso Law, PLLC we specialize in the legal concerns of senior
              citizens as well as younger individuals and families planning for
              the future.
            </h2>
            <p>
              Our attorneys have many years of experience consulting and
              planning for long-term care, in-home care, as well as assisted
              living.
            </p>
            <p>
              For more information please review our dedicated page for{" "}
              <Link to="/what-is-elder-law-medicaid-planning/">
                Elder Law &amp; Medicaid Planning
              </Link>
              .
            </p>
            <p>
              If you have immediate questions or concerns, don't hesitate to
              call or text <a href="tel:231-649-2903">231-649-2903</a>, or visit
              our contact page to <Link to="/contact-us/">send an email</Link>.
            </p>
            <p>
              You may also be interested in reading our{" "}
              <Link to="/estate-planning-probate-faqs/">
                Estate Planning &amp; Probate FAQS
              </Link>{" "}
              page.
            </p>
          </div>
        </div>
      </div>
    </section>
  </Layout>
)

export default ElderLawTraverseCity
